import React from "react";
import { graphql } from "gatsby";
import { SEO } from "../components/seo";
import Layout from "../components/layout";

import tw, { css } from "twin.macro";

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data; // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark;
  return (
    <Layout>
      <main tw="space-y-4 mx-auto max-w-4xl text-justify">
        <h1 tw="text-2xl font-bold">{frontmatter.title}</h1>
        <div
          css={[
            tw`space-y-4`,
            css`
              h2 {
                ${tw`text-xl font-bold !mt-8`}
              }

              p {
                ${tw`text-gray-300`}
              }

              a {
                ${tw`underline text-white`}
              }

              ul {
                ${tw`list-disc ml-6`}
              }

              ul li {
                ${tw``}
              }
            `,
          ]}
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </main>
    </Layout>
  );
}
export const pageQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
      }
    }
  }
`;

export const Head = () => <SEO title="About this site" />;
